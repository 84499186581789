import React from "react";
import Layout from "../../components/Layout";
import Contact from "../../components/Contact";
import { graphql } from "gatsby";
import MyHelmet from "../../context/MyHelmet";

export default ({ data: { wpPage } }) => {
  return (
    <div>
      <MyHelmet page="contact" language="es" meta={wpPage.seo} />
      <Layout>
        <Contact content={wpPage} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxMjgw" }) {
      title
      id
      contact {
        contactAreaText
        contactPerson
        fieldGroupName
        howItWorksTitle
        recieveFreeTitle
        howItWorksSteps {
          bullets
          fieldGroupName
          title
        }
      }
      content
      seo {
        metaDesc
        title
      }
    }
  }
`;
